import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Spinner, FormGroup, } from 'reactstrap';
import logoLight from "../../../../assets/images/logo-light.png";
//redux
import { useSelector, useDispatch } from "react-redux";


// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import ParticlesAuth from 'pages/AuthenticationInner/ParticlesAuth';
import { useAppDispatch, useAppSelector } from 'slices/hooks';
import { Role, SignInUserArgs } from 'types';
import { resetProfileFlagChange } from 'slices/auth/profile/reducer';
import GoogleLogInComponent from 'Components/Common/GoogleLogIn';
import { toast, ToastContainer } from 'react-toastify';
import { loginUser } from 'slices/thunks';
import { AvatarExample } from 'pages/BaseUi/UiImages/UiImagesCode';

const RoleValidation = (props: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [userType, setUserType] = useState<Role | null>(null)
    const { user: Profile } = useAppSelector(state => state.Profile)
    const { token } = useAppSelector(state => state.Login)
    document.title = "Backoffice | Parta Rentals";
    const [userLogin, setUserLogin] = useState<SignInUserArgs>({
        email: "",
        password: ""
    });
    const [passwordShow, setPasswordShow] = useState<boolean>(false);

    const { loading, user } = useAppSelector(state => state.Login)
    // Inside your component

    useEffect(() => {
        if (Profile) {
            dispatch(resetProfileFlagChange())
        }
    }, [Profile, dispatch, navigate]);

    useEffect(() => {
        if (user && user) {
            const updatedUserData = user.email;
            const updatedUserPassword = user.password;
            setUserLogin({
                email: updatedUserData,
                password: updatedUserPassword
            });
        }
    }, [user]);

    const validation: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: userLogin.email || '',
            password: userLogin.password || '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Por favor ingresa tu correo"),
            password: Yup.string().required("Por favor ingresa tu contraseña"),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.router.navigate)).catch((e) => {
                console.log("cdle")

            });
        }
    });


    const errornotify = (message: string) => toast(message, { position: "top-center", hideProgressBar: true, closeOnClick: false, className: 'bg-danger text-white' });
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-3 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="30" />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">Busca tu apartamento, consigue tu independencia</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className='justify-content-center'>
                            <Col lg={6}>
                                <Card className="overflow-hidden">

                                    <i className={`ri-group-line align-bottom me-1`}></i>

                                    <Row className="g-0">
                                        <Col lg={12}>
                                            <div className="p-lg-5 p-4 d-flex gap-3" style={{ flexDirection: "column" }}>
                                                <div className='text-center'>
                                                    <h5 className="text-primary">¿Qué tipo de usuario eres?</h5>
                                                </div>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input
                                                                name="role"
                                                                type="radio"
                                                                checked={userType === Role.Owner}
                                                                onChange={() => setUserType(Role.Owner)}
                                                            />
                                                            Usuario normal
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input
                                                                name="role"
                                                                type="radio"
                                                                checked={userType === Role.Agent}
                                                                onChange={() => setUserType(Role.Agent)}
                                                            />
                                                            {' '}
                                                            Agente inmobiliario
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                                <div>

                                                    <Label>
                                                        <Label className="text-primary fw-bold">Usuairo Normal:</Label> Para propietarios o inquilinos que gestionan o buscan alquilar propiedades personales.
                                                    </Label>
                                                    <Label>
                                                        <Label className="text-primary fw-bold">Agente inmobiliario:</Label> Para profesionales que gestionan propiedades en nombre de terceros.
                                                    </Label>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default RoleValidation;