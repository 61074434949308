import { ICurrency } from "types";

export const amountFormatter = (amount: number, currency?: ICurrency): string => {
    if(!currency) {
        return amount.toString();
    }
    let _amount = new Intl.NumberFormat('en-US').format(amount);

    if(amount < 0) {
        return _amount.replace('-', `-${currency?.simbolo}`);
    }
    return `${currency?.simbolo}${amount}`;
}