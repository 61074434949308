import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { UpdateContract } from 'service/contracts';
import { ContractStatus, IContract } from 'types/api/contracts';
import TemplatePreviewContracts from '../Template';

import { useHandleApiCall } from 'hooks/useHandleApiCall';
import { toast } from 'react-toastify';
import { MarkContractNonRenewable } from "service/contracts";
import { useAppSelector } from 'slices/hooks';
import { Role } from 'types';
import { CustomButton } from 'Components/UI/CustomButton';


const ThreeMothMilliSeconds = 1000 * 60 * 60 * 24 * 30 * 3
const TwelveMothMilliSeconds = 1000 * 60 * 60 * 24 * 30 * 12
const SixMothMilliSeconds = 1000 * 60 * 60 * 24 * 30 * 6
const ActiveContracts = () => {
  const { user } = useAppSelector(state => state.Profile)
  const navigate = useNavigate()
  const [modal_approve, set_modal_approve] = useState<boolean>(false);
  const [modal_finish_contract, set_modal_finish_contract] = useState<boolean>(false);
  const [selectedContract, setSelectedContract] = useState<IContract | null>(null);
  const handleMarkNonRenewableCall = useHandleApiCall(MarkContractNonRenewable)
  const handleUpdateContractCall = useHandleApiCall(UpdateContract)
  const [reload, setReload] = useState<(() => void) | undefined>(undefined);

  const tog_modal_finish_contract = () => {
    set_modal_finish_contract(prev => !prev);
  }
  const tog_approve_payment = () => {
    set_modal_approve(prev => !prev);
  }
  const [modal_declined, setmodal_declined] = useState<boolean>(false);
  const decline_payment = () => {
    setmodal_declined(prev => !prev);
  }

  const handleFinishContract = useCallback(async (contract: IContract) => {
    await handleUpdateContractCall.refetch(contract._id, { status: ContractStatus.PENDING_COMPLETION })
    reload && reload();

    toast.success("Finalización pendiente de aprobación por el propietario")
  }, [reload])

  const handleCancelFinishContract = useCallback(async (contract: IContract, refetch: any) => {
    await handleUpdateContractCall.refetch(contract._id, { status: ContractStatus.ACTIVE })
    refetch()
    toast.success("Solicitud de finalización cancelada")
  }, [])

  const handleCompleteContract = useCallback(async (contract: IContract) => {
    await handleUpdateContractCall.refetch(contract._id, { status: ContractStatus.COMPLETED })
    toast.success("Contrato completado")
  }, [])

  const handleMarkAsNonRenewable = useCallback(async (contract: IContract, cb: () => {}) => {
    try {
      await handleMarkNonRenewableCall.refetch(contract._id, {
        reason: "No renovable"
      })
      cb()
      toast.success("Contrato marcado como no renovable")
    } catch (error) {
      toast.error("Error al marcar contrato como no renovable")
    }
  }, [])

  return <>
    <TemplatePreviewContracts
      headerTitle='Contratos activos'
      statusFilter={[
        ContractStatus.ACTIVE,
        ContractStatus.PENDING_COMPLETION,
      ]}
      contractType="contracts"
      itemsActions={[

        {
          label: "Notificar no renovar",
          action: (v, reload) => {
            handleMarkAsNonRenewable(v, reload)
          },
          // @ts-ignore
          validation: (v) => {
            if (v.status === ContractStatus.PENDING_COMPLETION ||
              v.status === ContractStatus.REJECTED ||
              v.status === ContractStatus.PENDING_APPROVAL) {
              return false
            }
            if (v.tenant._id !== user?._id) {
              return false
            }
            return ((new Date(v.endDate).getTime() - Date.now()) > ThreeMothMilliSeconds) && v.status === ContractStatus.ACTIVE && (v.owner?._id === user?._id || v.agent?._id === user?._id)
          }
        },
        {
          label: "Renovar",
          action: (v) => {
            navigate(`/contracts-renew/${v._id}`)
          },
          // @ts-ignore
          validation: (v) => {
            if (v.status === ContractStatus.PENDING_COMPLETION ||
              v.status === ContractStatus.REJECTED ||
              v.status === ContractStatus.PENDING_APPROVAL) {
              return false
            }
            if (v.owner._id !== user?._id && v.agent._id !== user?._id) {
              return false
            }
            /* return user?._id === v.owner._id || user?._id === v.agent._id */

            return ((new Date(v.endDate).getTime() - Date.now()) > SixMothMilliSeconds) && v.status === ContractStatus.ACTIVE && (v.owner?._id === user?._id || v.agent?._id === user?._id) &&
              user?._id === v.owner._id || user?._id === v.agent._id

          }
        },
        {
          label: "Finalizar contrato",
          action: (v, reload) => {
            setReload(() => reload)
            setSelectedContract(v)
            tog_modal_finish_contract()
          },
          validation: (v) => v.status === ContractStatus.ACTIVE && v.tenant._id === user?._id && [Role.Tenant, Role.Admin].includes(user?.roles[0])
        },
        {
          label: "Cancelar Finalizacion",
          action: handleCancelFinishContract,
          validation: (v) => v.status === ContractStatus.PENDING_COMPLETION && v.tenant._id === user?._id && [Role.Tenant, Role.Admin].includes(user?.roles[0])
        },
        {
          label: "Ver detalle",
          action: (v) => { navigate(`/contracts-details/${v._id}`) },
          icon: "ri-eye-line",
        },
        {
          label: "Editar",
          action: (v) => { navigate(`/contracts-register/${v._id}`) },
          validation: (v) => (new Date(v.startDate).getTime() - Date.now()) > TwelveMothMilliSeconds && v.status === ContractStatus.ACTIVE // TODO Fix this validation last renew date
        },
        {
          label: "Reclamo de factura",
          action: (v) => {
            navigate(`/generate-claim-invoice/${v._id}`)
          },
          validation: (v) => v.status === ContractStatus.ACTIVE && v.tenant._id === user?._id && [Role.Tenant, Role.Admin].includes(user?.roles[0])
        },
      ]} />
    {/* Vertically Centered */}
    <Modal
      isOpen={modal_approve}
      toggle={() => {
        tog_approve_payment();
      }}
      centered
    >
      <ModalHeader className="modal-title" />

      <ModalBody className="text-center p-4">
        <i className="bx bx-check-shield text-success" style={{ fontSize: "60px" }}></i>
        <div className="mt-4">
          <h4 className="mb-3">¿Estas seguro de aprobar este pago?</h4>
          <p className="text-muted mb-4">Una vez aprobado no se podrá modificar</p>
          <div className="hstack gap-2 justify-content-center">
            <Button color="light" onClick={() => set_modal_approve(false)}>Cancelar</Button>
            <Button color="success" onClick={() => console.log("Funciona")}>Aprobar</Button>
          </div>
        </div>
      </ModalBody>
    </Modal>

    {/* Vertically Centered */}
    <Modal
      isOpen={modal_declined}
      toggle={() => {
        decline_payment();
      }}
      centered
    >
      <ModalHeader className="modal-title" />

      <ModalBody className="text-center p-4">
        <i className="ri-spam-fill text-danger" style={{ fontSize: "60px" }}></i>
        <div className="mt-4">
          <h4 className="mb-3">¿Estas seguro de rechazar este pago?</h4>
          <form action="">
            <div className="mb-3 text-start">
              <Label htmlFor="currency" className="form-label">Motivo del rechazo</Label>
              <select className="form-select mb-3" aria-label="Default select example">
                <option value="pago_incompleto">Pago incompleto</option>
              </select>
            </div>
            <div className="mb-3 text-start">
              <Label htmlFor="exampleFormControlTextarea5" className="form-label">Example Textarea</Label>
              <textarea className="form-control" id="exampleFormControlTextarea5" rows={3}></textarea>
            </div>
            <div className="hstack gap-2 justify-content-center">
              <Button color="light" onClick={() => setmodal_declined(false)}>Cancelar</Button>
              <Button color="danger" onClick={() => console.log("Funciona")}>Rechazar</Button>
            </div>
          </form>
        </div>
      </ModalBody>

    </Modal>
    <Modal
      isOpen={modal_finish_contract}
      toggle={tog_modal_finish_contract}
      centered
    >
      <ModalHeader className="modal-title" />
      <ModalBody className="text-center p-4">
        <i className="ri-spam-fill text-danger" style={{ fontSize: "60px" }}></i>
        <div className="mt-4">
          <h4 className="mb-3">¿Estas seguro de solicitar finalizar este contrato? "{selectedContract?.contractCode}"</h4>
          <form action="">
            <div className="mb-3 text-start">
              <Label htmlFor="exampleFormControlTextarea5" className="form-label">Una vez el propietario confirme la finalizacion no podra deshacer esta accion.</Label>
            </div>
            <div className="hstack gap-2 justify-content-center">
              <CustomButton color="light" onClick={() => set_modal_finish_contract(false)}>Cancelar</CustomButton>
              <CustomButton loading={handleUpdateContractCall.loading} color="danger" onClick={() => {
                if (!selectedContract) return
                handleFinishContract(selectedContract)
              }}>Finalizar</CustomButton>
            </div>
          </form>
        </div>
      </ModalBody>
    </Modal>
  </>
};

export default ActiveContracts;