import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

//import images
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";
import logoSm from "../assets/images/logo-sm.png";

//import Components
import FullScreenDropdown from '../Components/Common/FullScreenDropdown';
import LightDark from '../Components/Common/LightDark';
import ProfileDropdown from '../Components/Common/ProfileDropdown';

import { useHandleApiCall } from 'hooks/useHandleApiCall';
import { useSelector } from "react-redux";
import { createSelector } from 'reselect';
import * as UserService from 'service/users';
import { profileSuccess } from 'slices/auth/profile/reducer';
import { useAppDispatch, useAppSelector } from 'slices/hooks';
import { Role } from 'types';
import { changeSidebarVisibility } from '../slices/thunks';

const RolesEquivalent = {
    [Role.Admin]: "Admin",
    [Role.Owner]: "Propietario",
    [Role.Agent]: "Agente",
    [Role.Tenant]: "Inquilino",
}


const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }: any) => {
    const { user } = useAppSelector(state => state.Profile);
    const dispatch = useAppDispatch();
    const history = useNavigate();
    const handleUpdateUserApiCall = useHandleApiCall(UserService.Update, {
        onSuccess: (data) => {
            history("/")
        }
    })
    const selectDashboardData = createSelector(
        (state) => state.Layout.sidebarVisibilitytype,
        (sidebarVisibilitytype) => sidebarVisibilitytype
    );
    // Inside your component
    const sidebarVisibilitytype = useSelector(selectDashboardData);


    const [search, setSearch] = useState<boolean>(false);
    const [toggle, setToggle] = useState<boolean>(false);
    const toogleSearch = () => {
        setSearch(!search);
    };
    const toggleRole = (v: any) => {
        console.log(v.target.checked)
        if (user?._id) {
            updateUserHandleSubmit({
                _id: user?._id,
                roles: [v.target.checked ? Role.Owner : Role.Tenant],
                email: user?.email,
                name: user?.name,
                emailVerified: user?.emailVerified,
                password: user?.password,
                phone: user?.phone,
                surname: user?.surname,
                verifyToken: user?.verifyToken,
                address: user?.address,
            })
        }
    };

    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;
        const humberIcon = document.querySelector(".hamburger-icon") as HTMLElement;
        dispatch(changeSidebarVisibility("show"));

        if (windowSize > 767)
            humberIcon.classList.toggle('open');

        //For collapse horizontal menu
        if (document.documentElement.getAttribute('data-layout') === "horizontal") {
            document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
        }

        //For collapse vertical and semibox menu
        if (sidebarVisibilitytype === "show" && (document.documentElement.getAttribute('data-layout') === "vertical" || document.documentElement.getAttribute('data-layout') === "semibox")) {
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ? document.documentElement.setAttribute('data-sidebar-size', '') : document.documentElement.setAttribute('data-sidebar-size', 'sm');
            } else if (windowSize > 1025) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg');
            } else if (windowSize <= 767) {
                document.body.classList.add('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
        }


        //Two column menu
        if (document.documentElement.getAttribute('data-layout') === "twocolumn") {
            document.body.classList.contains('twocolumn-panel') ? document.body.classList.remove('twocolumn-panel') : document.body.classList.add('twocolumn-panel');
        }
    };

    useEffect(() => {
        if (user?.roles[0] === Role.Owner) {
            setToggle(true)
        } else if (user?.roles[0] === Role.Tenant) {
            setToggle(false)
        }
    }, [user?.roles])


    const updateUserHandleSubmit = useCallback(async (e: UserService.IUpdateUser) => {
        let data = await handleUpdateUserApiCall.refetch({
            id: e._id,
        }, e)
        dispatch(profileSuccess({ status: "success", data }))
        return data
    }, [dispatch])

    return (
        <React.Fragment>
            <header id="page-topbar" className={headerClass}>
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex align-items-center">

                            <div className="navbar-brand-box horizontal-logo">
                                <Link to="/" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoDark} alt="" height="17" />
                                    </span>
                                </Link>

                                <Link to="/" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoLight} alt="" height="17" />
                                    </span>
                                </Link>
                            </div>

                            <button
                                onClick={toogleMenuBtn}
                                type="button"
                                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                                id="topnav-hamburger-icon">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>


                            {/* <SearchOption /> */}
                            {
                                (user?.roles && [Role.Tenant, Role.Owner].includes(user?.roles[0])) &&
                                <div className="form-check form-switch form-switch-lg" dir="ltr">
                                    <input type="checkbox" className="form-check-input" id="customSwitchsizelg" checked={toggle} onChange={toggleRole} />
                                    <label className="form-check-label" htmlFor="customSwitchsizelg" ><span className='fw-bold'>Rol:</span> {(user?.roles && user.roles[0]) ? RolesEquivalent[user?.roles[0]] : ""}</label>
                                </div>
                            }
                        </div>


                        <div className="d-flex align-items-center">
                            {/* 
                            <Dropdown isOpen={search} toggle={toogleSearch} className="d-md-none topbar-head-dropdown header-item">
                                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                                    <i className="bx bx-search fs-22"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                                    <Form className="p-3">
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search ..."
                                                    aria-label="Recipient's username" />
                                                <button className="btn btn-primary" type="submit"><i
                                                    className="mdi mdi-magnify"></i></button>
                                            </div>
                                        </div>
                                    </Form>
                                </DropdownMenu>
                            </Dropdown> */}

                            {/* LanguageDropdown */}
                            {/* <LanguageDropdown /> */}

                            {/* WebAppsDropdown */}
                            {/* <WebAppsDropdown /> */}

                            {/* MyCartDropdwon */}
                            {/* <MyCartDropdown /> */}

                            {/* FullScreenDropdown */}
                            <FullScreenDropdown />

                            {/* Dark/Light Mode set */}
                            <LightDark
                                layoutMode={layoutModeType}
                                onChangeLayoutMode={onChangeLayoutMode}
                            />

                            {/* NotificationDropdown */}
                            {/* <NotificationDropdown /> */}

                            {/* ProfileDropdown */}
                            <ProfileDropdown />
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment >
    );
};

export default Header;