import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import Select from "react-select";
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Label,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    TabPane,
    Tooltip
} from 'reactstrap';
import classnames from "classnames";

import EyeIcon from '../../../../assets/images/icons/eye-icon.png'
import InfoIcon from '../../../../assets/images/icons/infoIcon.png'
import Scene1 from '../../../../assets/images/apartments/1.jpg'
import Scene2 from '../../../../assets/images/apartments/2.jpg'
import Scene3 from '../../../../assets/images/apartments/3.jpg'
import Scene4 from '../../../../assets/images/apartments/4.jpg'
import Scene5 from '../../../../assets/images/apartments/5.jpg'

//import avatar1 from '../../../../assets/images/users/profile-logo.jpeg';
import BreadCrumb from 'Components/Common/BreadCrumb';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { GoogleApiWrapper } from "google-maps-react";
import Dropzone from 'react-dropzone';
import config from '../../../../configs/app.config';
import Nominatim from "nominatim-geocoder";
import {
    TileLayer,
    Marker,
    Popup,
    MapContainer,
    useMapEvents,
    useMap
} from "react-leaflet";

import {
    GetProvince,
    GetCanton,
    GetDistrict,
} from "../../../../service/ubications";
import {
    CreateProperty,
    GetProperty,
    UpdateProperty,
} from "../../../../service/properties";
import { GetAgents, GetAgentsById } from 'service/agents';
import { toast } from 'react-toastify';
import AutoComplete from "../../../Maps/GoogleMaps/GoogleMapsAutoComplete";
import MapGoogle from 'pages/Maps/GoogleMaps/GoogleMapIntegration';
import { divIcon, gridLayer, LatLng } from 'leaflet';
import { StaticBackdropModalExample } from 'pages/BaseUi/UiModals/UiModalCode';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Scrollbar, EffectFade, EffectCreative, Mousewheel, EffectFlip, EffectCoverflow, Autoplay } from "swiper/modules";

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { useFormik } from 'formik';
import { FilePondFile } from 'filepond';
import { buffer } from 'stream/consumers';
import { ICreateProperty, Role } from 'types';
import { IPropertyInitValue } from './constants';
import Loader from 'Components/Common/Loader';
import * as Yup from "yup";
import { useAppSelector } from 'slices/hooks';
import { initialState } from 'slices/layouts/reducer';
import { DNITypesOptions } from 'Components/constants/data';
import { useDebounceCallback, useDebounceValue } from 'usehooks-ts';
import { GetUserByDNI } from 'service/users';
import { useHandleApiCall } from 'hooks/useHandleApiCall';
import { CustomButton } from 'Components/UI/CustomButton';

registerPlugin(FilePondPluginFileValidateType)

const titleOptions = [
    {
        label: "Propio",
        value: "0",
    },
    {
        label: "Tercero",
        value: "1",
    },
];


const formInitValue: IPropertyInitValue = {
    title: "",
    description: "",
    currency: {
        label: "Dólar Estadounidense",
        value: {
            code: "USD",
            name: "Dólar Estadounidense",
            symbol: "$",
        },
    },
    ownerTitle: titleOptions[0],
    numberOfRooms: { value: "", label: "" },
    numberOfBathrooms: { value: "", label: "" },
    numberOfParkingSpaces: { value: "", label: "" },
    priceInDollars: "",
    priceInColones: "",
    cashback: 0,
    featured: false,
    discountedPriceInDollars: "",
    discountedPriceInColones: "",
    constructionYear: { value: "", label: "" },
    surface: "",
    furnished: false,
    province: { value: "", label: "" },
    canton: { value: "", label: "" },
    district: { value: "", label: "" },
    address: "",
    lng: { lat: 0, lng: 0 },
    postalCode: "",
    airConditioning: false,
    garden: false,
    terrace: false,
    walkingCloset: false,
    panoramicView: false,
    coffeeMachine: false,
    kitchen: false,
    dryer: false,
    washingMachine: false,
    refrigerator: false,
    rampOrElevator: false,
    waterHeater: false,
    microwave: false,
    toaster: false,
    indoorParking: false,
    petFriendly: false,
    grill: false,
    petArea: false,
    pool: false,
    sauna: false,
    playground: false,
    tennisCourt: false,
    BBQranch: false,
    security: false,
    gym: false,
    internet: false,
    electricity: false,
    maintenanceFee: false,
    water: false,
    tv: false,
    hospital: false,
    publicTransport: false,
    school: false,
    malls: false,
    supermarkets: false,
    status: false,
    sold: false,
    office: false,
    visitorParking: false,
    blender: false,
    images: [],
    owner: "",
}

const mapStyles = {
    height: "100%",
};

type Coordinates = {
    lat: number;
    lng: number;
};

const Parking = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
];

const RoomNumbers = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
];

const BathRoomNumbers = [
    { value: "1", label: "1" },
    { value: "1.5", label: "1.5" },
    { value: "2", label: "2" },
    { value: "2.5", label: "2.5" },
    { value: "3", label: "3" },
    { value: "3.5", label: "3.5" },
    { value: "4", label: "4" },
    { value: "4.5", label: "4.5" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
];

const ConstructionYears = [
    { value: "0-5", label: "0-5" },
    { value: "6-10", label: "6-10" },
    { value: "11-15", label: "11-15" },
    { value: "16-20", label: "16-20" },
    { value: "21-25", label: "21-25" },
    { value: "30 o más...", label: "30 o más..." },
];

const currencyOptions = [
    {
        code: "USD",
        name: "Dólar Estadounidense",
        symbol: "$",
    },
    {
        code: "CRC",
        name: "Colón Costarricense",
        symbol: "₡",
    },
];

const acceptedFileTypes = ["image/jpeg", "image/jpg"];

const LocationMarker = ({ coord }: { coord: Coordinates }) => {
    const mapRefresh = useMap();
    const customIcon = divIcon({
        className: "custom-marker-icon",
        html: `<i class="mdi mdi-map-marker icon-large"></i>`,
        iconSize: [41, 41],
    });

    /*  console.log("valor de coordenadas!!!", coord); */
    const [position, setPosition] = useState<LatLng | null>(null);

    useEffect(() => {
        /* console.log("Entre aqui!!!"); */
        setPosition(new LatLng(coord.lat, coord.lng));
        mapRefresh.setView([coord.lat, coord.lng], 13);
    }, [coord, mapRefresh]);

    const map = useMapEvents({
        click() {
            map.locate();
        },
        locationfound(e) {
            /* console.log("evento maps", e.latlng); */
            setPosition(e.latlng);
            map.flyTo(e.latlng, map.getZoom());
        },
    });

    return position === null ? null : (
        <Marker position={coord} icon={customIcon}>
            <Popup>You are here</Popup>
        </Marker>
    );
};


const PropertyRegister = (props: any) => {
    const history = useNavigate();
    const { user } = useAppSelector(state => state.Profile)
    const [owner, setOwner] = useState(user);

    const [ownerDniType, setOwnerDniType] = useState(owner?.dni?.split("-")[0] ?? DNITypesOptions[0].value);
    const [ownerDniNumber, setOwnerDniNumber] = useState(owner?.dni?.split("-")[1] ?? "");
    const [fullOwnerDni] = useDebounceValue(`${ownerDniType}-${ownerDniNumber}`, 500)

    const { _id } = useParams();
    const [activeTab, setActiveTab] = useState("1");
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [filesPreview, setFilesPreview] = useState<File[]>([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showCarouselModal, setShowCarouselModal] = useState<boolean>(false);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [agent, setAgent] = useState<any>();
    const [loading, setLoading] = useState(false);

    const [coordinates, setCoordinates] = useState<Coordinates>({
        lat: 10.2735633,
        lng: -84.0739102,
    });
    const [agentData, setAgentData] = useState<any>();
    const [cantonData, setCantonData] = useState<any>();
    const [provinceData, setProvinceData] = useState<any>();
    const [districtData, setDistrictData] = useState<any>();
    const [previewImages, setPreviewImages] = useState<any>();

    const [submited, setSubmited] = useState<boolean>(false)


    const [formValues, setFormValues] = useState(formInitValue);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    const handleUpdatePropertyApiCall = useHandleApiCall(UpdateProperty)
    const handleCreatePropertyApiCall = useHandleApiCall(CreateProperty)

    const handleAcceptedFiles = useCallback((newFiles: FilePondFile[]) => {
        if (newFiles.length === files.length) return;
        if (newFiles.length > 10) {
            toast.error("No puedes subir más de 10 imágenes");
            return;
        }

        const invalidFile = newFiles.some(file => !acceptedFileTypes.includes(file.fileType));
        if (invalidFile) {
            toast.error(`Solo puedes subir imágenes en formato ${acceptedFileTypes.map(type => type.split("/")[1]).join(", ")}`);
        }

        const acceptedFiles = newFiles.filter(file => acceptedFileTypes.includes(file.fileType));

        Promise.all(acceptedFiles.map(el => el.file.arrayBuffer())).then((buffers) => {
            setSelectedFiles(buffers.map((buffer, index) => {
                return new File([buffer], acceptedFiles[index].filename, { type: acceptedFiles[index].fileType })
            }))

            setFiles(buffers.map((buffer, index) => {
                return new File([buffer], acceptedFiles[index].filename, { type: acceptedFiles[index].fileType })
            }))
            setPreviewImages(buffers.map(el => URL.createObjectURL(new Blob([el]))));
        })
    }, [files.length]);



    const handleReorderFiles = useCallback(async (newFiles: FilePondFile[]) => {
        if (newFiles.length > 10) {
            return toast.error("No puedes subir más de 10 imágenes")
        }
        let buffers = await Promise.all(newFiles.map(el => el.file.arrayBuffer()))

        setSelectedFiles(buffers.map((buffer, index) => {
            return new File([buffer], newFiles[index].filename, { type: newFiles[index].fileType })
        }))
        setFiles(buffers.map((buffer, index) => {
            return new File([buffer], newFiles[index].filename, { type: newFiles[index].fileType })
        }))
        setPreviewImages(buffers.map(el => URL.createObjectURL(new Blob([el]))));
    }, [])

    const tabChange = (tab: any) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const getAllAgents = async () => {
        const response: any = await GetAgents();
        const Agents = response.map((obj: any) => ({
            value: obj._id,
            label: obj.fullName,
        }));

        /* console.log("Carga de agentes!!!", Agents); */
        setAgentData(Agents);
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        getProvince();
        getAllAgents();
        // getCanton('all');
        if (_id) {
            getProperty();
        }
    }, [_id]);

    useEffect(() => {
        getProvince();
        const { province, canton, district, address } = formValues;
        // searchAddress(province.value, canton.value, district.value, address);
        // Realiza otras acciones según los valores de los estados adicionales
    }, [formValues]);
    const getProperty = async () => {
        if (_id)
            GetProperty(_id)
                .then(async (response: any) => {
                    // NO CORS IMAGE EXAMPLE https://ghost.hacksoft.io/content/images/2021/03/Kamen_article_1.jpg
                    const fetchImages =
                        response.images.map((image: any) => {
                            const URL = image.url
                            if (!URL) return null
                            return fetch(URL)
                                .then(res => res.blob())
                                .then(blob => {
                                    return new File([blob], URL.split("/").pop(), { type: "image/jpeg" });

                                }).catch(el => console.log(el));
                        })
                    const images = await Promise.all(fetchImages);

                    setFiles(images.filter(el => el));

                    setFormValues({
                        ...formValues,
                        title: response.detail.title,
                        description: response.detail.description,
                        agent: response.detail.agent,
                        currency: {
                            label: response.detail.currency.name,
                            value: response.detail.currency,
                        },
                        featured: response.detail.featured,
                        numberOfRooms: {
                            label: response.detail.numberOfRooms.toString(),
                            value: response.detail.numberOfRooms.toString(),
                        },
                        numberOfParkingSpaces: {
                            label: response.detail.numberOfParkingSpaces.toString(),
                            value: response.detail.numberOfParkingSpaces.toString(),
                        },
                        numberOfBathrooms: {
                            label: response.detail.numberOfBathrooms.toString(),
                            value: response.detail.numberOfBathrooms.toString(),
                        },
                        priceInDollars: response.detail.priceInDollars,
                        priceInColones: response.detail.priceInColones,
                        cashback: response.detail.cashback,
                        discountedPriceInDollars: response.detail.discountedPriceInDollars,
                        discountedPriceInColones: response.detail.discountedPriceInColones,
                        constructionYear: {
                            label: response.detail.constructionYear.toString(),
                            value: response.detail.constructionYear.toString(),
                        },
                        surface: response.detail.surface,
                        province: {
                            label: response.location.province,
                            value: response.location.province,
                        },
                        canton: {
                            label: response.location.canton,
                            value: response.location.canton,
                        },
                        district: {
                            label: response.location.district,
                            value: response.location.district,
                        },
                        address: response.location.direction,
                        postalCode: response.location.postalCode,
                        airConditioning: response.additionalCharacteristics.airConditioning,
                        garden: response.additionalCharacteristics.garden,
                        grill: response.additionalCharacteristics.grill,
                        microwave: response.additionalCharacteristics.microwave,
                        rampOrElevator: response.additionalCharacteristics.rampOrElevator,
                        refrigerator: response.additionalCharacteristics.refrigerator,
                        terrace: response.additionalCharacteristics.terrace,
                        toaster: response.additionalCharacteristics.toaster,
                        walkingCloset: response.additionalCharacteristics.walkingCloset,
                        panoramicView: response.additionalCharacteristics.panoramicView,
                        coffeeMachine: response.additionalCharacteristics.coffeeMachine,
                        kitchen: response.additionalCharacteristics.kitchen,
                        dryer: response.additionalCharacteristics.dryer,
                        washingMachine: response.additionalCharacteristics.washingMachine,
                        waterHeater: response.additionalCharacteristics.waterHeater,
                        indoorParking: response.additionalCharacteristics.indoorParking,
                        petFriendly: response.additionalCharacteristics.petFriendly,
                        office: response.additionalCharacteristics.office,
                        furnished: response.additionalCharacteristics.furnished,
                        BBQranch: response.amenities.BBQranch,
                        gym: response.amenities.gym,
                        visitorParking: response.amenities.visitorParking,
                        petArea: response.amenities.petArea,
                        pool: response.amenities.pool,
                        sauna: response.amenities.sauna,
                        playground: response.amenities.playground,
                        tennisCourt: response.amenities.tennisCourt,
                        security: response.amenities.security,
                        electricity: response.includedServices.electricity,
                        internet: response.includedServices.internet,
                        maintenanceFee: response.includedServices.maintenanceFee,
                        tv: response.includedServices.tv,
                        water: response.includedServices.water,
                        hospital: response.surroundings.hospital,
                        malls: response.surroundings.malls,
                        school: response.surroundings.school,
                        publicTransport: response.surroundings.publicTransport,
                        supermarkets: response.surroundings.supermarkets,
                    });
                    if (response.location.lat && response.location.lng) {
                        setCoordinates({
                            lat: response.location.lat,
                            lng: response.location.lng,
                        });
                    }
                    setAgent({
                        value: response.detail?.agent?._id,
                        label: response.detail?.agent?.fullName,
                    });
                    setLoading(false);
                })
                .catch((err) => {
                    /* console.log("Error al editar", err); */
                });
    };

    const geocoder = new Nominatim({
        secure: true,
    });

    const getProvince = async () => {
        GetProvince()
            .then((response: any) => {
                /* console.log("valor de response province", response); */
                const mappedOptions = response.map((option: string) => ({
                    value: option,
                    label: option,
                }));
                /* console.log("valores de provincias!!!", mappedOptions); */
                setProvinceData(mappedOptions);
            })
            .catch((error) => {
                console.log(
                    "Ocurrió un error al obtener los datos de provincia",
                    error
                );
            });
    };

    const getCanton = (province: string) => {
        GetCanton(province)
            .then((response: any) => {
                /* console.log("valor de response canton", response); */
                const mappedOptions = response.map((option: string) => ({
                    value: option,
                    label: option,
                }));
                /*  console.log("valores de provincias!!!", mappedOptions); */
                setCantonData(mappedOptions);
            })
            .catch((error) => {
                console.log(
                    "Ocurrió un error al obtener los datos de provincia",
                    error
                );
            });
    };

    const getDistrict = (canton: string) => {
        GetDistrict(formValues.province.value, canton)
            .then((response: any) => {
                /* console.log("valor de response canton", response); */
                const mappedOptions = response.map((option: string) => ({
                    value: option,
                    label: option,
                }));
                /* console.log("valores de provincias!!!", mappedOptions); */
                setDistrictData(mappedOptions);
            })
            .catch((error) => {
                console.log(
                    "Ocurrió un error al obtener los datos de provincia",
                    error
                );
            });
    };


    const getAgentSelected = async (selectedSingle: any) => {
        /*  console.log("valor de handleSingle", selectedSingle); */
        const agent = await GetAgentsById(selectedSingle.value);
        /* console.log("valor de Agente seleccionado!!!", agent); */
        // @ts-ignore
        setFormValues((prevState) => ({
            ...prevState,
            agent: agent,
        }));
    };


    function formatBytes(bytes: any, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const searchAddress = async (
        province: string,
        canton: string,
        district: string,
        address: string
    ) => {
        try {
            const addressCompleted = `Costa Rica, ${province}, ${canton}, ${district}, ${address}`;
            /* console.log("validando direccion completa!!!", addressCompleted); */
            const results = await geocoder.search({ q: addressCompleted });
            /* console.log("valor de resultados", results); */
            if (results && results.length > 0) {
                const { lat, lon } = results[0];
                /* console.log("valor de lat", lat); */
                /* console.log("valor de long", lon); */
                setCoordinates({ lat: parseFloat(lat), lng: parseFloat(lon) });
            }
        } catch (error) {
            console.error("Error searching address:", error);
        }
    };

    const handleValidateCashBack = (event: any) => {
        if (event.target.value === "" || !isNaN(event.target.value)) {
            setFormValues({
                ...formValues,
                cashback: event.target.value === "" ? 0 : Number(event.target.value),
            });
        }
    };

    const saveProperties = () => {
        setLoading(true);

        const saveProperty: ICreateProperty = {
            detail: {
                title: formValues.title,
                description: formValues.description,
                agent: formValues.agent,
                currency: formValues.currency.value,
                featured: formValues.featured,
                cashback: formValues.cashback,
                numberOfRooms: Number(formValues.numberOfRooms.value),
                numberOfBathrooms: Number(formValues.numberOfBathrooms.value),
                numberOfParkingSpaces: Number(formValues.numberOfParkingSpaces.value),
                priceInDollars:
                    formValues.currency.value.code === "USD"
                        ? parseInt(formValues.priceInDollars)
                        : 0,
                priceInColones:
                    formValues.currency.value.code != "USD"
                        ? parseInt(formValues.priceInColones)
                        : 0,
                discountedPriceInDollars: parseInt(formValues.discountedPriceInDollars),
                discountedPriceInColones: parseInt(formValues.discountedPriceInColones),
                constructionYear: formValues.constructionYear.value,
                surface: parseInt(formValues.surface),
                numberOfVisits: 0,
            },
            location: {
                province: formValues.province.value,
                canton: formValues.canton.value,
                district: formValues.district.value,
                direction: formValues.address,
                // country: formValues.address.value,
                lat: coordinates.lat,
                lng: coordinates.lng,
                postalCode: formValues.postalCode,
            },
            additionalCharacteristics: {
                airConditioning: formValues.airConditioning,
                garden: formValues.garden,
                terrace: formValues.terrace,
                walkingCloset: formValues.walkingCloset,
                panoramicView: formValues.panoramicView,
                coffeeMachine: formValues.coffeeMachine,
                kitchen: formValues.kitchen,
                dryer: formValues.dryer,
                washingMachine: formValues.washingMachine,
                refrigerator: formValues.refrigerator,
                rampOrElevator: formValues.rampOrElevator,
                waterHeater: formValues.waterHeater,
                microwave: formValues.microwave,
                toaster: formValues.toaster,
                grill: formValues.grill,
                indoorParking: formValues.indoorParking,
                petFriendly: formValues.petFriendly,
                furnished: formValues.furnished,
                office: !!formValues.office,
            },
            amenities: {
                petArea: formValues.petArea,
                pool: formValues.pool,
                sauna: formValues.sauna,
                playground: formValues.playground,
                tennisCourt: formValues.tennisCourt,
                BBQranch: formValues.BBQranch,
                security: formValues.security,
                gym: formValues.gym,
                visitorParking: formValues.visitorParking,
            },
            includedServices: {
                internet: formValues.internet,
                electricity: formValues.electricity,
                maintenanceFee: formValues.maintenanceFee,
                water: formValues.water,
                tv: formValues.tv,
            },
            surroundings: {
                hospital: formValues.hospital,
                publicTransport: formValues.publicTransport,
                school: formValues.school,
                malls: formValues.malls,
                supermarkets: formValues.supermarkets,
            },
            images: files,
            status: {
                status: true,
                sold: false,
            },
            created: new Date(),
            agent: user?._id,
            owner: owner?._id,
        };


        if (selectedFiles.length >= 5 && !_id) {

            // const response = await CreateProperty(formData);
            handleCreatePropertyApiCall.refetch(saveProperty)
                .then(async (response) => {
                    setLoading(false);
                    toast.success("Propiedad creada con éxito");
                    if (response) history("/pages-property-lists");
                })
                .catch((error) => {
                    setLoading(false);
                    console.log("Error al crear la propiedad", error);
                    if (
                        error &&
                        error.response &&
                        error.response.data &&
                        error.response.data.message
                    ) {
                        toast.error(error.response.data.message);
                    } else {
                        toast.error("Ha ocurrido un error");
                    }
                });
        } else if (selectedFiles.length < 5 && !_id) {
            setLoading(false);
            toast.error("Por favor agregue 5 imágenes como mínimo");
        } else {
            const id = _id ?? "";

            // const response = UpdateProperty(_id, formData);
            handleUpdatePropertyApiCall.refetch(id, saveProperty)
                .then(async (response) => {
                    /* console.log("response update properties", response); */
                    setLoading(false);
                    toast.success("Propiedad actualizada con éxito");
                    if (response) history("/pages-property-lists");
                })
                .catch((err) => {
                    toast.error("Error al actualizar la propiedad");
                    console.log("Error al actualizar", err);
                    console.log("Error al actualizar", err.response);
                    setLoading(false);
                });
        }
    };

    document.title = "Properties Register";

    const toggleConfirmationModal = () => {
        setShowConfirmationModal(prev => !prev);
    }

    const toggleSuccessModal = () => {
        if (showConfirmationModal) {
            toggleConfirmationModal()
        }
        setShowSuccessModal(prev => !prev);
    }

    const toggleCarouselModal = () => {
        setShowCarouselModal(prev => !prev);
    }

    const toggleWarningModal = () => {
        setShowWarningModal(prev => !prev);
    }

    const handlePropertiesValidation = useFormik({
        enableReinitialize: true,
        initialValues: formInitValue,
        onSubmit: toggleConfirmationModal,
        validationSchema: Yup.object({
            title: Yup.string().required("Por favor ingresa el titulo de la propiedad"),
            description: Yup.string().required("Por favor ingresa el titulo de la descripcion"),
            currency: Yup.object({
                value: Yup.object({
                    code: Yup.string().required("Por favor ingresa el codigo"),
                    name: Yup.string().required("Por favor ingresa nombre"),
                    symbol: Yup.string().required("Por favor ingresa simbolo"),
                }),
                label: Yup.string().required("Por favor ingresa la moneda"),
            }).required("Por favor ingresa la moneda"),
            ownerTitle: Yup.object().required("Por favor ingresa ownerTitle"),
            numberOfRooms: Yup.object({
                label: Yup.string().required("Por favor ingresa la cantidad de habitaciones"),
            }).required("Por favor ingresa numberOfRooms"),
            numberOfBathrooms: Yup.object({
                label: Yup.string().required("Por favor ingresa la cantidad de baños"),
            }).required("Por favor ingresa numberOfBathrooms"),
            numberOfParkingSpaces: Yup.object({
                label: Yup.string().required("Por favor ingresa la cantidad de parqueos"),
            }).required("Por favor ingresa la cantidad de parqueos"),
            constructionYear: Yup.object({
                value: Yup.string().required("Por favor ingresa los años de construcción"),
                label: Yup.string().required("Por favor ingresa los años de construcción"),
            }).required("Por favor ingresa los años de construcción"),
            province: Yup.object({
                value: Yup.string().required("Por favor ingresa una provician"),
                label: Yup.string().required("Por favor ingresa una provician"),
            }).required("Por favor ingresa una provician"),
            canton: Yup.object({
                value: Yup.string().required("Por favor ingresa canton"),
                label: Yup.string().required("Por favor ingresa canton"),
            }).required("Por favor ingresa canton"),
            district: Yup.object({
                value: Yup.string().required("Por favor ingresa un distrito"),
                label: Yup.string().required("Por favor ingresa un distrito"),
            }).required("Por favor ingresa un distrito"),
            address: Yup.string().required("Por favor ingresa dirección"),
            /* lng: Yup.object().shape({
                lat: Yup.number().required("Por favor ingresa lat"),
                lng: Yup.number().required("Por favor ingresa lng"),
            }).required("Por favor ingresa lng"), */
            surface: Yup.string().required("Por favor ingresa los metros cuadrados"),
        }),

    })
    useEffect(() => {
        handlePropertiesValidation.setValues(formValues)
    }, [formValues])

    useEffect(() => {
        function array_equal(arr1: string[], arr2: string[]) {
            if ((Array.isArray(arr1) && Array.isArray(arr2)) === false) return false;
            // @ts-ignore
            return JSON.stringify([...new Set(arr1.flat().sort())]) === JSON.stringify([...new Set(arr2.flat().sort())]);
        }

        if (!array_equal(files.map(el => el.name), filesPreview.map(el => el.name))) {
            setFilesPreview(files.map(el => el));
        }
    }, [files, filesPreview])

    const validateSubmit = useCallback(() => {
        const anyError = Object.values(handlePropertiesValidation.errors).some(el => el)
        if (anyError)
            toast.error("Por favor complete los campos requeridos")
        if (
            handlePropertiesValidation.errors.title ||
            handlePropertiesValidation.errors.description ||
            handlePropertiesValidation.errors.currency ||
            handlePropertiesValidation.errors.ownerTitle ||
            handlePropertiesValidation.errors.numberOfRooms ||
            handlePropertiesValidation.errors.numberOfBathrooms ||
            handlePropertiesValidation.errors.numberOfParkingSpaces ||
            handlePropertiesValidation.errors.constructionYear ||
            handlePropertiesValidation.errors.surface
        ) {
            setActiveTab("1")
        } else if (
            handlePropertiesValidation.errors.province ||
            handlePropertiesValidation.errors.canton ||
            handlePropertiesValidation.errors.district ||
            handlePropertiesValidation.errors.address ||
            handlePropertiesValidation.errors.lng
        ) {
            setActiveTab("2")
        } else if (
            false
        ) {
        } else if (
            handlePropertiesValidation.errors.images
        ) {
            setActiveTab("4")
        }

    }, [handlePropertiesValidation])


    useEffect(() => {
        console.log(handlePropertiesValidation.errors)
    }, [handlePropertiesValidation.errors])

    useEffect(() => {
        setFormValues(formInitValue)
    }, [history])

    const handleGetOwnerUser = useDebounceCallback(async (fullDni) => {
        try {
            const _owner = await GetUserByDNI(fullDni)
            if (_owner._id === user?._id) {
                return setOwner(null)
            }
            if (_owner) {
                setOwner(_owner)
                handlePropertiesValidation.setValues({
                    ...handlePropertiesValidation.values,
                    owner: _owner._id
                })
                setFormValues(prev => ({
                    ...prev,
                    owner: _owner._id
                }))
            }
        } catch (error) {
            setOwner(null)
            console.log(error)
        }
    }, 500)

    useEffect(() => {
        if (formValues.ownerTitle.value === '1')
            handleGetOwnerUser(fullOwnerDni)
    }, [formValues.ownerTitle.value, fullOwnerDni])

    useEffect(() => {
        setOwner(user)
    }, [user])

    useEffect(() => {
        if (owner && user?.roles[0] === Role.Owner) {
            setOwnerDniNumber(owner?.dni?.split("-")[1] ?? "")
            setOwnerDniType(owner?.dni?.split("-")[0] ?? "")
        }
    }, [owner, user?.roles])

    useEffect(() => {
        if (user?.roles[0] === Role.Agent) {
            setFormValues((prev) => ({
                ...prev,
                ownerTitle: titleOptions[1]
            }))
        } else if (user?.roles[0] === Role.Owner) {
            setFormValues((prev) => ({
                ...prev,
                ownerTitle: titleOptions[0]
            }))
        }
    }, [user?.roles])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={`${_id ? "Editar" : "Subir"} una propiedad`} pageTitle="Pages" />
                    <Row>
                        <Col lg={12}>
                            <div>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                    role="tablist">
                                    <NavItem style={{ cursor: 'pointer' }} className='nav-tabs-custom'>
                                        <NavLink
                                            className={classnames({ active: activeTab === "1" })}
                                            onClick={() => {
                                                tabChange("1");
                                            }}>
                                            <i className="fas fa-home tab-item"></i>
                                            General
                                        </NavLink>
                                    </NavItem>

                                    <NavItem style={{ cursor: 'pointer' }} className='nav-tabs-custom'>
                                        <NavLink to="#"
                                            className={classnames({ active: activeTab === "2" })}
                                            onClick={() => {
                                                tabChange("2");
                                            }}>
                                            <i className="far fa-home"></i>
                                            Ubicación
                                        </NavLink>
                                    </NavItem>

                                    <NavItem style={{ cursor: 'pointer' }} className='nav-tabs-custom'>
                                        <NavLink to="#"
                                            className={classnames({ active: activeTab === "3" })}
                                            onClick={() => {
                                                tabChange("3");
                                            }}>
                                            <i className="far fa-envelope"></i>
                                            Características adicionales
                                        </NavLink>
                                    </NavItem>

                                    <NavItem style={{ cursor: 'pointer' }} className='nav-tabs-custom'>
                                        <NavLink to="#"
                                            className={classnames({ active: activeTab === "4" })}
                                            onClick={() => {
                                                tabChange("4");
                                            }}>
                                            <i className="far fa-envelope"></i>
                                            Fotografías
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={12}>
                            <Card className="">
                                <CardBody className="p-3">
                                    <Form onSubmit={(e) => {
                                        setSubmited(true)
                                        validateSubmit()
                                        handlePropertiesValidation.handleSubmit(e)
                                    }}>
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId="1">

                                                <Row>
                                                    <Col lg={12} >
                                                        <div className="mb-4 pb-2 ">
                                                            <h5 className="card-title">Información general de la propiedad</h5>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4} className='mb-3'>
                                                        <div >
                                                            <Label htmlFor="firstnameInput" className="form-label">Titularidad de la propiedad {''}<i className="ri-information-line" id='ToolTip-1' ></i>
                                                                <Tooltip
                                                                    isOpen={tooltipOpen}
                                                                    target="ToolTip-1"
                                                                    toggle={toggle}
                                                                >
                                                                    Señale si el titular de la propiedad es usted o un tercero. (En todo caso, el tercero también debe estar registrado en PartaRentals)
                                                                </Tooltip>
                                                            </Label>
                                                            <Select
                                                                value={formValues.ownerTitle}
                                                                onChange={(selectedOption: any) => {
                                                                    console.log(selectedOption)
                                                                    if (selectedOption.value === '0') {
                                                                        setOwner(user)
                                                                    } else if (selectedOption.value === '1') {
                                                                        setOwnerDniNumber('')
                                                                        setOwnerDniType(DNITypesOptions[0].value)
                                                                        setOwner(null)
                                                                    }
                                                                    setFormValues({
                                                                        ...formValues,
                                                                        ownerTitle: selectedOption,
                                                                    });
                                                                }}
                                                                options={(() => {
                                                                    if (user?.roles[0] === Role.Agent) {
                                                                        return titleOptions.filter(option => option.value === '1')
                                                                    } else if (user?.roles[0] === Role.Owner) {
                                                                        return titleOptions.filter(option => option.value === '0')
                                                                    } else if (user?.roles[0] === Role.Admin) {
                                                                        return titleOptions
                                                                    }
                                                                })()}
                                                                style={{
                                                                    zindex: 2,
                                                                }}
                                                                placeholder="Seleccione una opción"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} className='mb-3'>
                                                        {
                                                            titleOptions[1].value === formValues.ownerTitle.value &&

                                                            <div className="mb-3">
                                                                <Label htmlFor="JoiningdatInput" className="form-label">Cédula de identidad</Label>
                                                                <Row>
                                                                    <Col lg={4} className='mb-3'>
                                                                        <Select
                                                                            value={DNITypesOptions.find(option => option.value === ownerDniType)}
                                                                            onChange={(selectedOption: any) => {
                                                                                setOwnerDniType(selectedOption.value)
                                                                            }}
                                                                            options={DNITypesOptions}
                                                                            style={{
                                                                                zindex: 2,
                                                                            }}
                                                                            placeholder="Tipo de documento"
                                                                        />

                                                                    </Col>

                                                                    <Col lg={8}>
                                                                        <Input
                                                                            type="number"
                                                                            name="dniNumber"
                                                                            id="dniNumber"
                                                                            className="form-control"
                                                                            placeholder="Ingrese su cédula de identidad"
                                                                            value={ownerDniNumber}
                                                                            onChange={(e) => setOwnerDniNumber(e.target.value ?? "")}
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                            </div>


                                                            /*  <div >
                                                                <Label htmlFor="firstnameInput" className="form-label">Correo registrado a Partarentals del Tercero</Label>
                                                                <div>
                                                                    <Input id="exampleEmail" name="text" placeholder="" type="email" value={fullOwnerDni} onChange={(e) => setOwnerEmail(e.target.value)} />
                                                                </div>
                                                            </div>
 */


                                                        }
                                                    </Col>
                                                    <Col lg={4} className='mb-3'>
                                                        {
                                                            titleOptions[1].value === formValues.ownerTitle.value &&
                                                            <div >
                                                                <Label htmlFor="firstnameInput" className="form-label">Nombre y apellido del titular</Label>
                                                                <div>
                                                                    <Input id="name-1" name="name-1" placeholder="" type="text" disabled={true} value={`${owner?.name ?? ""} ${owner?.surname ?? ""}`} />
                                                                </div>
                                                            </div>
                                                        }
                                                    </Col>
                                                    <Col lg={12} className='mb-3'>

                                                        <Label htmlFor="JoiningdatInput" className="form-label">Nombre de la propiedad</Label>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div>
                                                                    <Input

                                                                        className="form-control"
                                                                        type="text"
                                                                        value={formValues.title}
                                                                        onChange={(e) =>
                                                                            setFormValues(prev => ({
                                                                                ...prev,
                                                                                title: e.target.value,
                                                                            }))
                                                                        }
                                                                        name='title'
                                                                        onBlur={handlePropertiesValidation.handleBlur}
                                                                    />
                                                                    {submited &&
                                                                        <Label className='text-danger'><div>{handlePropertiesValidation.errors.title}</div></Label>
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                    </Col>
                                                    <Col lg={12} className='mb-3'>
                                                        <Label htmlFor="JoiningdatInput" className="form-label">Descripción de la propiedad</Label>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <CardBody className='position-relative p-0'>
                                                                    <textarea className='form-control'
                                                                        value={formValues.description}
                                                                        onChange={(e) =>
                                                                            setFormValues(prev => ({
                                                                                ...prev,
                                                                                description: e.target.value,
                                                                            }))
                                                                        }
                                                                        rows={5}
                                                                    />

                                                                    {submited &&
                                                                        <Label className='text-danger'><div>{handlePropertiesValidation.errors.description}</div></Label>
                                                                    }


                                                                </CardBody>
                                                            </Col>
                                                        </Row>

                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className='mb-3'>
                                                            <Label>Habitaciones</Label>
                                                            <div>
                                                                <Select
                                                                    value={formValues.numberOfRooms}
                                                                    onChange={(selectedOption: any) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            numberOfRooms: selectedOption,
                                                                        })
                                                                    }
                                                                    options={RoomNumbers}
                                                                    placeholder=""
                                                                />
                                                                {submited &&
                                                                    <Label className='text-danger'>
                                                                        <div>{handlePropertiesValidation.errors.numberOfRooms?.label}</div>
                                                                    </Label>}
                                                            </div>
                                                        </div>

                                                        <div className='mb-3'>
                                                            <Label className=''>Años de construcción</Label>
                                                            <div>
                                                                <Select
                                                                    value={formValues.constructionYear}
                                                                    onChange={(selectedOption: any) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            constructionYear: selectedOption,
                                                                        })
                                                                    }
                                                                    options={ConstructionYears}
                                                                    placeholder=""
                                                                />
                                                                {submited &&
                                                                    <Label className='text-danger'>
                                                                        <div>{handlePropertiesValidation.errors.constructionYear?.label}</div>
                                                                    </Label>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className='mb-3'>
                                                            <Label className=''>Moneda de pago</Label>
                                                            <Select
                                                                value={formValues.currency}
                                                                onChange={(selectedOption: any) =>
                                                                    setFormValues({
                                                                        ...formValues,
                                                                        currency: selectedOption,
                                                                    })
                                                                }
                                                                options={currencyOptions.map((option) => ({
                                                                    value: option,
                                                                    label: option.name,
                                                                }))}
                                                                placeholder=""
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Row>
                                                            <Col lg={12} className='mb-3'>
                                                                <Label>Baños</Label>
                                                                <Select
                                                                    value={formValues.numberOfBathrooms}
                                                                    onChange={(selectedOption: any) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            numberOfBathrooms: selectedOption,
                                                                        })
                                                                    }
                                                                    options={BathRoomNumbers}
                                                                    placeholder=""
                                                                />
                                                                {submited &&
                                                                    <Label className='text-danger'>
                                                                        <div>{handlePropertiesValidation.errors.numberOfBathrooms?.label}</div>
                                                                    </Label>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={12} className='mb-3'>
                                                                <Label className=''>Superficie en m²</Label>
                                                                <div>
                                                                    <Input
                                                                        className="form-control"
                                                                        id="choices-text-remove-button"
                                                                        value={formValues.surface}
                                                                        onChange={(e) =>
                                                                            setFormValues({
                                                                                ...formValues,
                                                                                surface: e.target.value,
                                                                            })
                                                                        }
                                                                        data-choices
                                                                        data-choices-limit="3"
                                                                        type="number"
                                                                        defaultValue="0"
                                                                    />
                                                                    {submited &&
                                                                        <Label className='text-danger'>
                                                                            <div>{handlePropertiesValidation.errors.surface}</div>
                                                                        </Label>
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={12} className='mb-3'>
                                                                <Label className=''>Precio en {formValues.currency.label}</Label>
                                                                <Input
                                                                    className="form-control"
                                                                    id="choices-text-remove-button"
                                                                    value={
                                                                        formValues.currency.value.code === "USD"
                                                                            ? formValues.priceInDollars
                                                                            : formValues.priceInColones
                                                                    }
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            [formValues.currency.value.code === "USD"
                                                                                ? "priceInDollars"
                                                                                : "priceInColones"]: e.target.value,
                                                                        })
                                                                    }
                                                                    type="number"
                                                                    placeholder={
                                                                        formValues.currency.value.symbol + "0"
                                                                    }
                                                                />
                                                                {submited &&
                                                                    <Label className='text-danger'>
                                                                        <div>{handlePropertiesValidation.errors.currency?.label}</div>
                                                                    </Label>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <Label>Parqueo</Label>
                                                                <Select
                                                                    value={formValues.numberOfParkingSpaces}
                                                                    onChange={(selectedOption: any) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            numberOfParkingSpaces: selectedOption,
                                                                        })
                                                                    }
                                                                    options={Parking}
                                                                    placeholder=""
                                                                />
                                                                {submited &&
                                                                    <Label className='text-danger'>
                                                                        <div>{handlePropertiesValidation.errors.numberOfParkingSpaces?.label}</div>
                                                                    </Label>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        {/* <Row>
                                                            <Col lg={12}>
                                                            <Label>Baños</Label>
                                                                <div>
                                                                    <Input bsSize="lg" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={12}>
                                                            <Label>Baños</Label>
                                                                <div>
                                                                    <Input bsSize="lg" />
                                                                </div>
                                                            </Col>
                                                        </Row> */}
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="hstack gap-2 justify-content-end mt-3">
                                                            <button type="button"
                                                                className="btn btn-success w-100-mobile" onClick={() => setActiveTab("2")}>Siguiente paso</button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="mb-4 pb-2">
                                                            <h5 className="card-title">Ubicación de la propiedad</h5>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={6} className='mb-3'>
                                                        <Label>Provincia</Label>
                                                        <Select
                                                            className="properties-form-select-province"
                                                            value={formValues.province}
                                                            onChange={(selectedOption: any) => {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    province: selectedOption,
                                                                });
                                                                getCanton(selectedOption.value);
                                                            }}
                                                            options={provinceData}
                                                            placeholder=""
                                                        />
                                                        {submited &&
                                                            <Label className='text-danger'>
                                                                <div>{handlePropertiesValidation.errors.province?.label}</div>
                                                            </Label>
                                                        }
                                                    </Col>
                                                    <Col lg={6} className='mb-3'>
                                                        <Label>Municipio / Cantón</Label>
                                                        <Select
                                                            className="properties-form-select"
                                                            value={formValues.canton}
                                                            onChange={(selectedOption: any) => {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    canton: selectedOption,
                                                                });
                                                                getDistrict(selectedOption.value);
                                                            }}
                                                            options={cantonData}
                                                            placeholder=""
                                                        />
                                                        {submited &&
                                                            <Label className='text-danger'>
                                                                <div>{handlePropertiesValidation.errors.canton?.label}</div>
                                                            </Label>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={6} className='mb-3'>
                                                        <Label>Distrito</Label>
                                                        <Select
                                                            className="properties-form-select"
                                                            value={formValues.district}
                                                            onChange={(selectedOption: any) =>
                                                                setFormValues({
                                                                    ...formValues,
                                                                    district: selectedOption,
                                                                })
                                                            }
                                                            options={districtData}
                                                            placeholder=""
                                                        />
                                                        {submited &&
                                                            <Label className='text-danger'>
                                                                <div>{handlePropertiesValidation.errors.district?.label}</div>
                                                            </Label>
                                                        }
                                                    </Col>
                                                    <Col lg={6} className='mb-3'>
                                                        <Label>Dirección</Label>
                                                        <AutoComplete
                                                            addressValue={formValues.address}
                                                            getCoords={setCoordinates}
                                                            address={(
                                                                adrs: string,
                                                                prov: string,
                                                                dstr: string
                                                            ) =>
                                                                setFormValues({
                                                                    ...formValues,
                                                                    address: adrs,
                                                                })
                                                            }
                                                        />
                                                        {submited &&
                                                            <Label className='text-danger'>
                                                                <div>{handlePropertiesValidation.errors.address}</div>
                                                            </Label>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={12}>
                                                        <div>
                                                            <div id="gmaps-types" className="gmaps" style={{ position: "relative" }}>
                                                                <MapGoogle
                                                                    coords={coordinates}
                                                                    setCoords={setCoordinates}
                                                                    draggableMap={true}
                                                                    zoom={8}
                                                                    styles={mapStyles}
                                                                />
                                                                {submited &&
                                                                    <Label className='text-danger'><div>{handlePropertiesValidation.errors.lng?.lat}</div></Label>
                                                                }
                                                                {submited &&
                                                                    <Label className='text-danger'><div>{handlePropertiesValidation.errors.lng?.lng}</div></Label>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className='mt-3'>
                                                    <Col xs={12} lg={6}>
                                                        <div className="hstack gap-2 justify-content-start">
                                                            <button type="button" onClick={() => setActiveTab("1")}
                                                                className="btn btn-light w-100-mobile mb-2-mobile">Atras</button>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} lg={6}>
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <button type="button" onClick={() => setActiveTab("3")}
                                                                className="btn btn-success w-100-mobile">Siguiente paso</button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="3">
                                                <Row>
                                                    <div className="mb-4 pb-2">
                                                        <h5 className="card-title">Características adicionales de la propiedad</h5>
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <Col lg={12} className='fs-16 fw-semibold'>
                                                        <Label>Características</Label>
                                                    </Col>

                                                    <Col md={6} lg={4} xl={3} className='fs-13'>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck1"
                                                                    checked={formValues.furnished}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            furnished: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Amoblado / Semiamoblado
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck2"
                                                                    checked={formValues.airConditioning}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            airConditioning: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Aire acondicionado
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck3"
                                                                    checked={formValues.garden}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            garden: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Jardín / Patio
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck4"
                                                                    checked={formValues.terrace}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            terrace: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Terraza
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck5"
                                                                    checked={formValues.walkingCloset}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            walkingCloset: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Walk-in closet
                                                            </Label>
                                                        </div>
                                                    </Col>

                                                    <Col md={6} lg={4} xl={3} className='fs-13'>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck6"
                                                                    checked={formValues.panoramicView}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            panoramicView: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Vista panoramica
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck18"
                                                                    checked={formValues.petFriendly}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            petFriendly: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Pet Friendly
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck7"
                                                                    checked={formValues.rampOrElevator}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            rampOrElevator: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Rampa / Elevador
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck8"
                                                                    checked={formValues.waterHeater}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            waterHeater: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Calentador de agua
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck9"
                                                                    checked={formValues.microwave}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            microwave: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Microondas
                                                            </Label>
                                                        </div>
                                                    </Col>

                                                    <Col md={6} lg={4} xl={3} className='fs-13'>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck10"
                                                                    checked={formValues.toaster}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            toaster: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Tostadora
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck11"
                                                                    checked={formValues.grill}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            grill: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Parrillera
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck12"
                                                                    checked={formValues.indoorParking}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            indoorParking: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Parqueo con techo
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck13"
                                                                    checked={formValues.kitchen}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            kitchen: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Cocina / Horno
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck14"
                                                                    checked={formValues.dryer}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            dryer: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Secadora
                                                            </Label>
                                                        </div>
                                                    </Col>

                                                    <Col md={6} lg={4} xl={3} className='fs-13'>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck15"
                                                                    checked={formValues.coffeeMachine}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            coffeeMachine: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Máquina de café
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck16"
                                                                    checked={formValues.washingMachine}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            washingMachine: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Lavadora
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck17"
                                                                    checked={formValues.refrigerator}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            refrigerator: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Nevera
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck38"
                                                                    checked={formValues.office}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            office: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Oficina
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck38"
                                                                    checked={formValues.blender}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            blender: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Licuadora
                                                            </Label>
                                                        </div>
                                                    </Col>

                                                </Row>
                                                <Row className='mt-1'>
                                                    <Col md={6} lg={4} xl={3}>
                                                        <Label className='fs-16 mt-3'>Amenidades</Label>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck19"
                                                                    checked={formValues.petArea}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            petArea: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Área de mascotas
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck20"
                                                                    checked={formValues.pool}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            pool: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Piscinas
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck21"
                                                                    checked={formValues.playground}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            playground: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Parque infantil
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck22"
                                                                    checked={formValues.sauna}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            sauna: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Bodega
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck23"
                                                                    checked={formValues.tennisCourt}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            tennisCourt: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Cancha deportiva
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck24"
                                                                    checked={formValues.BBQranch}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            BBQranch: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Rancho BBQ
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck25"
                                                                    checked={formValues.security}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            security: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Seguridad 24/7
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck26"
                                                                    checked={formValues.gym}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            gym: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Gimnasio
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck39"
                                                                    checked={formValues.visitorParking}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            visitorParking: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Parqueos de visita
                                                            </Label>
                                                        </div>
                                                    </Col>

                                                    <Col md={6} lg={4} xl={3}>
                                                        <Label className='fs-16 mt-3'>Servicios incluidos</Label>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck27"
                                                                    checked={formValues.internet}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            internet: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Internet
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck28"
                                                                    checked={formValues.electricity}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            electricity: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Servicio eléctrico
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck29"
                                                                    checked={formValues.maintenanceFee}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            maintenanceFee: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Cuota de mantenimiento
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck30"
                                                                    checked={formValues.water}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            water: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Agua
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck31"
                                                                    checked={formValues.tv}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            tv: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Tv por cable
                                                            </Label>
                                                        </div>
                                                    </Col>

                                                    <Col md={6} lg={4} xl={3}>
                                                        <Label className='fs-16 mt-3'>Cercanías</Label>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck32"
                                                                    checked={formValues.hospital}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            hospital: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Hospital
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck33"
                                                                    checked={formValues.publicTransport}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            publicTransport: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Transporte público
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck34"
                                                                    checked={formValues.school}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            school: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Escuela
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck35"
                                                                    checked={formValues.malls}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            malls: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Centros comerciales
                                                            </Label>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Label check style={{ marginLeft: '5px' }}>
                                                                <Input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="formCheck36"
                                                                    checked={formValues.supermarkets}
                                                                    onChange={(e) =>
                                                                        setFormValues({
                                                                            ...formValues,
                                                                            supermarkets: e.target.checked,
                                                                        })
                                                                    }
                                                                />{"   "}
                                                                Supermercado
                                                            </Label>
                                                        </div>
                                                    </Col>

                                                </Row>
                                                <Row className='mt-4'>
                                                    <Col xs={12} lg={6}>
                                                        <div className="hstack gap-2 justify-content-start">
                                                            <button type="button" onClick={() => setActiveTab("2")}
                                                                className="btn btn-light w-100-mobile mb-2-mobile">Atras</button>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} lg={6}>
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <button type="button" onClick={() => setActiveTab("4")}
                                                                className="btn btn-success w-100-mobile">Siguiente paso</button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="4">
                                                <Row>
                                                    <div className="mb-4 pb-2">
                                                        <h5 className="card-title">Fotografías de la propiedad</h5>
                                                    </div>
                                                </Row>
                                                <Row className='text-muted'>
                                                    <Col lg={8}>
                                                        <Label>
                                                            Sube entre 5 y 10 fotografías de la propiedad. La primera imagen será la portada en el sitio web. <br />
                                                            <span style={{ fontWeight: 'bold' }}>Requisitos:</span> Resolución ideal de 1920x1080 y un tamaño máximo de 1 MB por foto. <br />
                                                            <span style={{ fontWeight: 'bold' }}>Consejo:</span> Utiliza TinyPNG (https://tinypng.com) para comprimir las imágenes sin perder calidad.
                                                        </Label>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <button type="button"
                                                                className="btn fw-semibold"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#staticBackdrop"
                                                                style={{ color: 'turquoise' }} onClick={toggleCarouselModal}>
                                                                <img src={EyeIcon} alt='EyeIcon' className='mr-1' /> Ver fotos de ejemplo
                                                            </button>

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className='mt-1'>
                                                    <CardBody>
                                                        <FilePond
                                                            files={filesPreview}
                                                            onupdatefiles={handleAcceptedFiles}
                                                            allowMultiple={true}
                                                            onreorderfiles={handleReorderFiles}
                                                            name="files"
                                                            className="filepond filepond-input-multiple "
                                                            allowReorder={true}
                                                            maxFiles={10}
                                                            onwarning={(error) => {
                                                                if (error.body === "Max files") {
                                                                    toast.error("Error: Solo puedes subir 10 fotos");
                                                                }
                                                            }}
                                                            acceptedFileTypes={[
                                                                "image/jpg",
                                                                "image/jpeg",
                                                            ]}

                                                        />
                                                    </CardBody>
                                                </Row>
                                                <Row className='mt-4'>
                                                    <Col xs={12} lg={6}>
                                                        <div className="hstack gap-2 justify-content-start">
                                                            <button type="button" onClick={() => setActiveTab("3")}
                                                                className="btn btn-light w-100-mobile mb-2-mobile">Atras</button>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} lg={6}>
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <button type="submit"
                                                                className="btn btn-success w-100-mobile">Finalizar</button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Confirmation */}

            <Modal
                isOpen={showConfirmationModal && !loading}
                toggle={toggleConfirmationModal}
                backdrop={'static'}
                id="staticBackdrop"
                centered
            >
                <ModalHeader className="modal-title" id="staticBackdropLabel" toggle={toggleConfirmationModal}>


                </ModalHeader>
                <ModalBody className="text-center p-4">
                    <i className="ri-error-warning-line display-2 ext-warning" style={{ color: "orange" }}></i>

                    <div className="mt-4">
                        <h4 className="mb-3">¿Estás seguro de publicar esta propiedad?</h4>
                        <p className="text-muted mb-4"> La propiedad estará disponible una vez sea aprobada por el equipo de PartaRentals, se te notificará por correo electrónico.</p>
                        <div className="hstack gap-2 justify-content-center">
                            <CustomButton className="btn btn-light waves-effect" onClick={toggleConfirmationModal}>Cancelar</CustomButton>
                            <CustomButton loading={handleCreatePropertyApiCall.loading || handleUpdatePropertyApiCall.loading} className="btn btn-success" onClick={() => saveProperties()}>Publicar</CustomButton>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            {/* Confirmation */}

            {/* Success */}

            <Modal
                isOpen={showSuccessModal}
                toggle={toggleSuccessModal}
                backdrop={'static'}
                id="staticBackdrop"
                centered
            >
                <ModalHeader className="modal-title" id="staticBackdropLabel" toggle={toggleSuccessModal}>


                </ModalHeader>
                <ModalBody className="text-center p-5">
                    <i className="bx bx-party display-4 text-success"></i>

                    <div className="mt-4">
                        <h4 className="mb-3">Publicación subida exitosamente</h4>
                        <p className="text-muted mb-4"> En menos de 24 horas te informaremos sobre el estado de esta publicación</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Link to="#" className="btn btn-success" onClick={toggleSuccessModal}>Cerrar</Link>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            {/* Success */}
            {/* Carousel */}

            <Modal
                isOpen={showCarouselModal}
                toggle={toggleCarouselModal}
                backdrop={'static'}
                id="staticBackdrop"
                size="lg"
                centered
            >
                <ModalHeader className="modal-title" id="staticBackdropLabel" toggle={toggleCarouselModal}>
                    Fotos de ejemplo al subir una propiedad
                </ModalHeader>
                <ModalBody className="text-center p-3.5">
                    <CardBody>
                        <Swiper modules={[Navigation, Pagination, Autoplay]} pagination={{ clickable: true }} navigation={true} loop={true} autoplay={{ delay: 2500, disableOnInteraction: false }} className="mySwiper swiper navigation-swiper rounded">
                            <div className="swiper-wrapper">
                                <SwiperSlide><img src={Scene1} alt="" className="img-fluid" /></SwiperSlide>
                                <SwiperSlide><img src={Scene2} alt="" className="img-fluid" /></SwiperSlide>
                                <SwiperSlide><img src={Scene3} alt="" className="img-fluid" /></SwiperSlide>
                                <SwiperSlide><img src={Scene4} alt="" className="img-fluid" /></SwiperSlide>
                                <SwiperSlide><img src={Scene5} alt="" className="img-fluid" /></SwiperSlide>
                            </div>
                        </Swiper>
                    </CardBody>
                </ModalBody>
            </Modal>
            {/* Carousel */}
            {/* Deletion */}
            <Modal
                isOpen={showWarningModal}
                toggle={toggleWarningModal}
                backdrop={'static'}
                id="staticBackdrop"
                centered
            >
                <ModalHeader className="modal-title" id="staticBackdropLabel" toggle={toggleWarningModal}>


                </ModalHeader>
                <ModalBody className="text-center p-5">
                    <i className="bx bx-party display-4 text-success"></i>

                    <div className="mt-4">
                        <h4 className="mb-3">Publicación subida exitosamente</h4>
                        <p className="text-muted mb-4"> En menos de 24 horas te informaremos sobre el estado de esta publicación</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Link to="#" className="btn btn-success" onClick={toggleWarningModal}>Cerrar</Link>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment >
    );
};

export default PropertyRegister

