import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { DeleteContract } from "service/contracts";
import { useAppSelector } from 'slices/hooks';
import { Role } from 'types';
import { ContractStatus, IContract } from 'types/api/contracts';
import TemplatePreviewContracts from '../Template';
import { useHandleApiCall } from 'hooks/useHandleApiCall';
import { CustomButton } from 'Components/UI/CustomButton';

const ContractOffers = () => {
  document.title = "Ofertas de contrato | Partarentals";
  const navigate = useNavigate()
  const [accept_payment_modal_center, set_accept_payment_modal_center] = useState<boolean>(false);
  const [reloadPage, setReloadPage] = useState<() => {}>(() => false);
  const [delete_offer_modal, set_delete_offer_modal] = useState<boolean>(false);
  const [selectedContract, setSelectedContract] = useState<IContract | null>(null);
  const tog_accept_payment = () => {
    set_accept_payment_modal_center(!accept_payment_modal_center);
  }
  const tog_delete_offer_modal = () => {
    set_delete_offer_modal(prev => !prev);
  }

  const handleDeleteContractApiCall = useHandleApiCall(DeleteContract)
  const { user } = useAppSelector(state => state.Profile)

  const handleFinishContract = useCallback(async () => {
    try {
      console.log(selectedContract)
      if (!selectedContract) return;
      await handleDeleteContractApiCall.refetch(selectedContract?._id);
      tog_delete_offer_modal();
      reloadPage();
      toast.success("Oferta retirada");
    } catch (error) {
      toast.error("Error al retirar la oferta");
    }
  }, [selectedContract, handleDeleteContractApiCall, reloadPage])

  return (
    <React.Fragment>
      <TemplatePreviewContracts
        headerTitle='Ofertas de contrato'
        statusFilter={[
          ContractStatus.PENDING_APPROVAL,
          ContractStatus.PENDING_PAYMENT_APPROVAL,
          ContractStatus.REJECTED_PAYMENT,
        ]}
        contractType="offers"
        itemsActions={
          [
            {
              label: "Ver detalle",
              action: (v) => {
                navigate(`/contracts-details/${v._id}`);
              },
              icon: "ri-eye-line"
            },
            {
              label: "Retirar oferta",
              icon: "ri-delete-bin-line",
              action: async (v, reloadPage) => {
                setSelectedContract(v);
                setReloadPage(() => reloadPage);
                tog_delete_offer_modal();
              },
              validation: (v) =>
                v.status === ContractStatus.PENDING_APPROVAL &&
                // @ts-ignore
                [Role.Admin, Role.Agent, Role.Owner].includes(user?.roles[0] || '') && (v.owner._id === user?._id || v.agent?._id === user?._id),
            },
          ]} />

      {/* Vertically Centered */}
      <Modal
        isOpen={accept_payment_modal_center}
        toggle={() => {
          tog_accept_payment();
        }}
        centered
      >
        <ModalHeader className="modal-title" />

        <ModalBody className="text-center p-4">
          <i className="bx bx-check-shield text-success" style={{ fontSize: "60px" }}></i>
          <div className="mt-4">
            <h4 className="mb-3">¿Estas seguro de aprobar este pago?</h4>
            <p className="text-muted mb-4">Una vez aprobado no se podrá modificar</p>
            <div className="hstack gap-2 justify-content-center">
              <Button color="light" onClick={() => set_accept_payment_modal_center(false)}>Cancelar</Button>
              <Button color="success" onClick={() => console.log("Funciona")}>Aprobar</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={delete_offer_modal}
        toggle={tog_delete_offer_modal}
        centered
      >
        <ModalHeader className="modal-title" />
        <ModalBody className="text-center p-4">
          <i className="ri-spam-fill text-danger" style={{ fontSize: "60px" }}></i>
          <div className="mt-4">
            <h4 className="mb-3">¿Estas seguro de retirar esta oferta de contrato contrato? "{selectedContract?.contractCode}"</h4>
            <form action="">
              <div className="mb-3 text-start">
                <Label htmlFor="exampleFormControlTextarea5" className="form-label">Una vez el propietario confirme la finalizacion no podra deshacer esta accion.</Label>
              </div>
              <div className="hstack gap-2 justify-content-center">
                <CustomButton color="light" onClick={() => set_delete_offer_modal(false)}>Cancelar</CustomButton>
                <CustomButton loading={handleDeleteContractApiCall.loading} color="danger" onClick={handleFinishContract}>Finalizar</CustomButton>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ContractOffers;