import { ICurrency } from "types/entities"


export enum PaymentMethodType {
    PAYPAL = 'paypal',
    CASH = 'cash',
    INTERNATIONAL_BANK_TRANSFER = 'bank_transfer',
    NATIONAL_BANK_TRANSFER = 'national_bank_transfer',
    SINPE = 'sinpe',
}

export const PaymentMethodMap = {
    [PaymentMethodType.INTERNATIONAL_BANK_TRANSFER]: "Transferencia bancaria",
    [PaymentMethodType.PAYPAL]: "Paypal",
    [PaymentMethodType.SINPE]: "Transferencia SINPE MOVIL",
    [PaymentMethodType.CASH]: "Efectivo",
    [PaymentMethodType.NATIONAL_BANK_TRANSFER]: "Transferencia bancaria nacional",
}
export interface PaypalDetails {
    email: string
}

export interface CashDetails {
}

export interface InternationalBankTransferDetails {
    accountName: string,
    bankName: string,
    accountNumber: string,
    swiftCode: string
}
export interface NationalBankTransferDetails {
    accountName: string,
    accountDocumentId: string,
    bankName: string,
    accountNumber: string,
    currency: string;
}

export interface SimpeDetails {
    accountName: string,
    phoneNumber: string
}


export interface ICreatePaymentMethodBase<T, R> {
    owner: string,
    name: string,
    type: T,
    details: R,
    isActive: boolean
}

export type ICreatePaymentMethodPaypal = ICreatePaymentMethodBase<PaymentMethodType.PAYPAL, PaypalDetails>
export type ICreatePaymentMethodCash = ICreatePaymentMethodBase<PaymentMethodType.CASH, CashDetails>
export type ICreatePaymentMethodInternationalBankTransfer = ICreatePaymentMethodBase<PaymentMethodType.INTERNATIONAL_BANK_TRANSFER, InternationalBankTransferDetails>
export type ICreatePaymentMethodNationalBankTransfer = ICreatePaymentMethodBase<PaymentMethodType.NATIONAL_BANK_TRANSFER, NationalBankTransferDetails>
export type ICreatePaymentMethodSinpe = ICreatePaymentMethodBase<PaymentMethodType.SINPE, SimpeDetails>

export type ICreatePaymentMethod = ICreatePaymentMethodPaypal | ICreatePaymentMethodCash | ICreatePaymentMethodInternationalBankTransfer | ICreatePaymentMethodSinpe | ICreatePaymentMethodNationalBankTransfer

export interface IUpdatePaymentMethod {
    owner?: string,
    name?: string,
    type?: PaymentMethodType,
    details?: {
        email?: string,
        accountNumber?: string,
        accountName?: string,
        bankName?: string,
        phoneNumber?: string
        swiftCode?: string,
        currency?: string
        accountDocumentId?: string
    },
    isActive?: boolean
}

export interface IPaymentMethod {
    _id: string
    owner: string,
    name: string,
    type: PaymentMethodType,
    details: {
        email?: string,
        accountNumber?: string
        accountName?: string
        bankName?: string
        phoneNumber?: string
        swiftCode?: string
        currency?: string
        accountDocumentId?: string
    },
    isActive: boolean
}

