import { useAppDispatch, useAppSelector } from "slices/hooks";
import { useSessionStorage } from "usehooks-ts";
import { SessionToken } from "types";
import { useEffect } from "react";
import { recoverSession } from "slices/thunks";
import { useNavigate } from "react-router-dom";

const authProtectedRoutes = [
    "/logout",
    "/login",
    "/forgot-password",
    "/register",
    "/auth-signin-basic",
    "/auth-signin-cover",
    "/auth-signup-basic",
    "/auth-signup-cover",
    "/auth-pass-reset-basic",
    "/auth-pass-reset-cover",
    "/auth-lockscreen-basic",
    "/auth-lockscreen-cover",
    "/auth-logout-basic",
    "/auth-logout-cover",
    "/auth-success-msg-basic",
    "/auth-success-msg-cover",
    "/auth-twostep-basic",
    "/auth-reset-password",
    "/auth-twostep-cover",
    "/auth-404-basic",
    "/auth-404-cover",
    "/auth-404-alt",
    "/auth-500",
]

const useInitApp = () => {
    const { user } = useAppSelector(state => state.Profile);
    const { token: tokenState } = useAppSelector(state => state.Login);
    const dispatch = useAppDispatch();
    const [tokenStorage, setTokenStorage] = useSessionStorage<SessionToken>("authUser", { token: "" });
    const navigate = useNavigate();
    useEffect(() => {
        if (tokenStorage.token && !user) {
            setTimeout(() => {
                dispatch(recoverSession(tokenStorage.token));
            }, 50)
        }
    }, [dispatch, tokenStorage, user])

    useEffect(() => {
        if (tokenState && !user && !tokenStorage.token) {
            setTokenStorage({ token: tokenState })
        }
    }, [dispatch, setTokenStorage, tokenState, tokenStorage, user])

    useEffect(() => {
        if (!tokenState && !tokenStorage.token) {
            if (!authProtectedRoutes.includes(window.location.pathname))
                navigate('login')
        } else {
            if (authProtectedRoutes.includes(window.location.pathname))
                navigate('dashboard')
        }
    }, [navigate, tokenState, tokenStorage])   
}

export default useInitApp;