import { amountFormatter } from 'helpers/number_helper';
import { useHandleApiCall } from 'hooks/useHandleApiCall';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, Label, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import { getAccountStatements, getTotalAmount } from 'service/account-statement';
import { GetContracts } from 'service/contracts';
import { useAppSelector } from 'slices/hooks';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { IUser, Role } from 'types';
import { AccountStatementStatus, IAccountStatement } from 'types/api/account-statement';
import { CustomButton } from 'Components/UI/CustomButton';
import { ContractStatus } from 'types/api/contracts';

const getColor = (user: IUser, accountStatement: IAccountStatement) => {
    console.log(accountStatement)
    const role = user.roles[0]
    const receiver = accountStatement.receiver
    if (role === Role.Tenant) {
        if (receiver === "tenant") {
            return "text-success"
        } else if (receiver === "owner") {
            return "text-danger"
        }
    }
    if (role === Role.Owner) {
        if (receiver === "owner") {
            return "text-success"
        } else if (receiver === "tenant") {
            return "text-danger"
        }
    }
    else {
        return "text-dark"
    }
}

const badgeText = {
    [AccountStatementStatus.APPROVED]: "APROBADO",
    [AccountStatementStatus.PENDING]: "PENDIENTE",
    [AccountStatementStatus.PAID]: "PAGADO",
    [AccountStatementStatus.OVERDUE]: "ATRASADO",
    [AccountStatementStatus.PENDING_APPROVAL]: "PENDING_APPROVAL",

}

const badgeColor = {
    [AccountStatementStatus.APPROVED]: "secondary",  //"#0AB39C",
    [AccountStatementStatus.PENDING]: "warning",  //"#F1A347",
    [AccountStatementStatus.PENDING_APPROVAL]: "warning",  //"#F1A347",
    [AccountStatementStatus.PAID]: "success",  //"#3577F1",
    [AccountStatementStatus.OVERDUE]: "danger",  //"#EA4335",
}

const AccountStateBadge = ({ status }: { status: `${AccountStatementStatus}` }) => {
    return <h5><Badge color={badgeColor[status]} className="badge-soft-primary font-size-12">{badgeText[status]}</Badge></h5>
}

const AccountState = () => {
    const { user } = useAppSelector(state => state.Profile)
    const navigate = useNavigate()
    const [paginationProps, setPaginationProps] = useState({
        limit: 10,
        offset: 0,
        skip: 0
    })
    const handleGetAccountStatementCall = useHandleApiCall(getAccountStatements)
    const handleGetContracts = useHandleApiCall(GetContracts)
    document.title = "Estado de cuenta | Partarentals";
    //const navigate = useNavigate()
    const [offset, setOffset] = useState(0);
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [pageSize, setPageSize] = useState<number>(0)
    const [canNextPage, setCanNextPage] = useState<boolean>(true)
    const [canPreviousPage, setCanPreviousPage] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [selectedContract, setSelectedContract] = useState<{ value: string; label: string } | null>(null)

    const pageOptions = useMemo(() => {
        return pageSize > 0 ? new Array(Math.ceil((pageSize / limit))).fill(null).map((el, i) => i) : []
    }, [limit, pageSize])
    console.log(pageSize)
    const [pageIndex, setPageIndex] = useState<number>(0)
    const nextPage = () => {
        if (page < pageOptions.length - 1) {
            setPage(page + 1)
        }
    }
    const previousPage = () => {
        if (page > 0) {
            setPage(page - 1)
        }
    }

    const gotoPage = (page: number) => {
        setPage(page)
    }


    const [modal_center, setmodal_center] = useState<boolean>(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }

    const [modal_declined, setmodal_declined] = useState<boolean>(false);
    function decline_payment() {
        setmodal_declined(!modal_declined);
    }


    useEffect(() => {

        if (selectedContract)
            handleGetAccountStatementCall.refetch({ ...paginationProps, contract: selectedContract?.value })
    }, [handleGetAccountStatementCall.refetch, paginationProps, selectedContract])

    useEffect(() => {
        handleGetContracts.refetch({
            status: [ContractStatus.ACTIVE]
        })
    }, [handleGetContracts.refetch])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Estado de cuenta" pageTitle="Pages" />
                    <Row className='mb-2'>
                        <Col md={4}>
                            <h5>Filtrar por contrato asociado</h5>
                            <Select
                                className="properties-form-select"
                                value={selectedContract}
                                onChange={(selectedOption: any) =>
                                    setSelectedContract(selectedOption)
                                }
                                options={handleGetContracts.data?.data.map((item) => {
                                    return { value: item._id, label: item.contractCode }
                                })}
                                placeholder="Seleccione un contrato"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader>
                                    <Row className='d-flex align-items-center'>
                                        <Col md={4} className=''>
                                            <h5 className="mb-0">Pagos vencidos</h5>
                                        </Col>
                                        <Col md={4} className=''>
                                            <h5 className="total-a-pagar">TOTAL A PAGAR:{" "}
                                                <span className='fw-bold'>{
                                                    amountFormatter(handleGetAccountStatementCall.data?.totalBalance ?? 0, handleGetContracts.data?.data.find((item) => item._id === selectedContract?.value)?.currency)
                                                }</span></h5>
                                        </Col>
                                        <Col md={4} className='text-end'>
                                            {
                                                /* user?.roles[0] === "tenant" */ true &&
                                                <CustomButton
                                                    disabled={!selectedContract || (handleGetAccountStatementCall.data?.totalBalance ?? 0) >= 0}
                                                    type="button"
                                                    className='btn btn-success w-100-mobile' o
                                                    onClick={() => {
                                                        selectedContract?.value && navigate(`/account-state/payment/${selectedContract?.value}`)
                                                    }}> Pagar deuda</CustomButton>
                                            }
                                        </Col>
                                        {/* <Col md={4} className='text-end'>
                                            <button type="button" className="btn btn-primary waves-effect waves-light w-100-mobile">Descargar XLXS <span><i className='bx bx-printer'></i></span></button>
                                        </Col> */}
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="live-preview">
                                        <div className="table-responsive">
                                            <Row className='d-flex align-items-center mb-2'>
                                                <Col xs={12}>
                                                    {!handleGetAccountStatementCall?.data?.data
                                                        ? <div className="no-information bg-light">
                                                            <p>No hay datos disponibles para visualizar</p>
                                                        </div>
                                                        : <Table className="align-middle table-nowrap mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    {/* <th scope="col" style={{ "width": "42px" }}>
                                                                    <div className="form-check">
                                                                        <Label className="form-check-label" htmlFor="responsivetableCheck"></Label>
                                                                    </div>
                                                                </th> */}
                                                                    <th scope="col">Contrato asociado</th>
                                                                    <th scope="col">Descripción</th>
                                                                    <th scope="col" className='text-center'>Monto</th>
                                                                    <th scope="col" className='text-center'>Creación</th>
                                                                    <th scope="col" className='text-center'>Vencimiento</th>
                                                                    <th scope="col" className='text-center'>Estatus</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    (handleGetAccountStatementCall?.data?.data ?? [])?.map((item) => {
                                                                        return <tr key={item._id}>
                                                                            {/* <th scope="row">
                                                                    <div className="form-check">
                                                                        <Label className="form-check-label" htmlFor="responsivetableCheck01"></Label>
                                                                    </div>
                                                                </th> */}
                                                                            <td><Link to={`/contracts-details/${item.contract._id}`} className='enlace-tabla'>{item.contract.contractCode}</Link></td>
                                                                            <td><p dangerouslySetInnerHTML={{
                                                                                __html: item.description
                                                                            }}></p></td>
                                                                            <td className={`text-center ${user && getColor(user, item)}`}>{amountFormatter(item.amount, handleGetContracts.data?.data[0].currency)}</td>
                                                                            <td className={`text-center`}>{new Date(item.creationDate ?? 0).toLocaleDateString()}</td>
                                                                            <td className={`text-center`}>{new Date(item.dueDate ?? 0).toLocaleDateString()}</td>
                                                                            <td className={`text-center`}><AccountStateBadge status={item.status} /></td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>}
                                                </Col>
                                            </Row>
                                            <Row className='d-flex align-items-center mb-2'>
                                                <Col sm className='text-start'>
                                                    <div className="">Mostrando<span className="fw-semibold ms-1">1</span> de <span className="fw-semibold">10</span> de <span className="fw-semibold">14</span> registros
                                                    </div>
                                                </Col>

                                                <Col sm={"auto"} className='text-end'>
                                                    <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                                                        <li className={!canPreviousPage ? "page-item disabled" : "page-item"}>
                                                            <Link to="#" className="page-link" onClick={previousPage}>Previous</Link>
                                                        </li>
                                                        {pageOptions.map((item: any, key: any) => (
                                                            <React.Fragment key={key}>
                                                                <li className="page-item">
                                                                    <Link to="#" className={page === item ? "page-link active" : "page-link"} onClick={() => gotoPage(item)}>{item + 1}</Link>
                                                                </li>
                                                            </React.Fragment>
                                                        ))}
                                                        <li className={!canNextPage ? "page-item disabled" : "page-item"}>
                                                            <Link to="#" className="page-link" onClick={nextPage}>Next</Link>
                                                        </li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} className='position-relative d-flex justify-content-end'>
                            <button type='button' className='btn btn-danger d-flex align-items-center justify-content-center' style={{ height: 37, width: 37 }}><i className='bx bx-up-arrow-alt' style={{ fontSize: 18 }}></i></button>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Vertically Centered */}
            <Modal
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center p-4">
                    <i className="bx bx-check-shield text-success" style={{ fontSize: "60px" }}></i>
                    <div className="mt-4">
                        <h4 className="mb-3">¿Estas seguro de aprobar este pago?</h4>
                        <p className="text-muted mb-4">Una vez aprobado no se podrá modificar</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button color="light" onClick={() => setmodal_center(false)}>Cancelar</Button>
                            <Button color="success" onClick={() => console.log("Funciona")}>Aprobar</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* Vertically Centered */}
            <Modal
                isOpen={modal_declined}
                toggle={() => {
                    decline_payment();
                }}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center p-4">
                    <i className="ri-spam-fill text-danger" style={{ fontSize: "60px" }}></i>
                    <div className="mt-4">
                        <h4 className="mb-3">¿Estas seguro de rechazar este pago?</h4>
                        <form action="">
                            <div className="mb-3 text-start">
                                <Label htmlFor="currency" className="form-label">Motivo del rechazo</Label>
                                <select className="form-select mb-3" aria-label="Default select example">
                                    <option value="pago_incompleto">Pago incompleto</option>
                                </select>
                            </div>
                            <div className="mb-3 text-start">
                                <Label htmlFor="exampleFormControlTextarea5" className="form-label">Example Textarea</Label>
                                <textarea className="form-control" id="exampleFormControlTextarea5" rows={3}></textarea>
                            </div>
                            <div className="hstack gap-2 justify-content-center">
                                <Button color="light" onClick={() => setmodal_declined(false)}>Cancelar</Button>
                                <Button color="danger" onClick={() => console.log("Funciona")}>Rechazar</Button>
                            </div>
                        </form>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    );
};

export default AccountState;